var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"padding1",staticStyle:{"background":"#f4f5fa"}},[(_vm.info==true)?_c('v-card',{staticClass:"elevation-0 pb-6 px-7",staticStyle:{"background":"transparent"}},[_c('v-card-text',{staticClass:"py-3 px-6",staticStyle:{"background":"#1976d1","color":"white","border-radius":"5px"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white","small":""}},[_vm._v("mdi-information-outline")]),_vm._v(" Lorem ipsum dolor sit amet consectetur adipiscing elit massa imperdiet, mauris tortor platea fringilla sodales ac magna sociosqu, natoque non rutrum nec auctor vestibulum congue eget. "),_c('v-icon',{staticClass:"mr-2",staticStyle:{"position":"absolute!important","top":"7px","right":"30px"},attrs:{"color":"white","small":""},on:{"click":function($event){_vm.info=false}}},[_vm._v("mdi-close")])],1)],1):_vm._e(),_c('v-card',{staticClass:"elevation-0 pb-4 padding2",staticStyle:{"background":"transparent"}},[_c('v-card-title',{staticStyle:{"background":"white"}},[_vm._v("Estatus "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Right tooltip")])]),_c('v-spacer'),(_vm.dialogStatus==true)?_c('v-text-field',{attrs:{"label":"Nombre del Estatus"}}):_vm._e(),_c('v-btn',{staticClass:"elevation-0",staticStyle:{"height":"30px","font-size":"13px","font-weight":"400","text-transform":"capitalize","color":"#1976d1","background-color":"rgb(25 118 209 / 10%)!important"},attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogStatus=true}}},[_vm._v("Agregar Nuevo")])],1),_c('statuses',{staticClass:"px-6"}),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogStatus),callback:function ($$v) {_vm.dialogStatus=$$v},expression:"dialogStatus"}},[_c('createStatus',{on:{"closeDialogStatus":_vm.closeDialogStatus}})],1)],1),_c('v-card',{staticClass:"elevation-0 py-4 padding2",staticStyle:{"background":"transparent"}},[_c('v-card-title',{staticStyle:{"background":"white"}},[_vm._v("Etapas "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Right tooltip")])]),_c('v-spacer'),(_vm.dialogPhase==true)?_c('v-text-field',{attrs:{"label":"Nombre de la Etapa"}}):_vm._e(),_c('v-btn',{staticClass:"elevation-0",staticStyle:{"height":"30px","font-size":"13px","font-weight":"400","text-transform":"capitalize","color":"#1976d1","background-color":"rgb(25 118 209 / 10%)!important"},attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogPhase=true}}},[_vm._v("Agregar Nuevo")])],1),_c('phases',{staticClass:"px-6"}),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogPhase),callback:function ($$v) {_vm.dialogPhase=$$v},expression:"dialogPhase"}},[_c('createPhases',{on:{"closeDialogPhase":_vm.closeDialogPhase}})],1)],1),_c('v-card',{staticClass:"elevation-0 py-4 padding2",staticStyle:{"background":"transparent"}},[_c('v-card-title',{staticStyle:{"background":"white"}},[_vm._v("Procedencias "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Right tooltip")])]),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0",staticStyle:{"height":"30px","font-size":"13px","font-weight":"400","text-transform":"capitalize","color":"#1976d1","background-color":"rgb(25 118 209 / 10%)!important"},attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogOrigin=true}}},[_vm._v("Agregar Nueva")])],1),_c('origins',{staticClass:"px-6"}),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogOrigin),callback:function ($$v) {_vm.dialogOrigin=$$v},expression:"dialogOrigin"}},[_c('createOrigins',{on:{"closeDialogOrigin":_vm.closeDialogOrigin}})],1)],1),_c('v-card',{staticClass:"elevation-0 py-4 padding2",staticStyle:{"background":"transparent"}},[_c('v-card-title',{staticStyle:{"background":"white"}},[_vm._v("Tipos de Actividad "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Right tooltip")])]),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0",staticStyle:{"height":"30px","font-size":"13px","font-weight":"400","text-transform":"capitalize","color":"#1976d1","background-color":"rgb(25 118 209 / 10%)!important"},attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogType=true}}},[_vm._v("Agregar Nuevo")])],1),_c('types',{staticClass:"px-6"}),_c('v-dialog',{attrs:{"max-width":"350px"},model:{value:(_vm.dialogType),callback:function ($$v) {_vm.dialogType=$$v},expression:"dialogType"}},[_c('createTypes',{on:{"closeDialogType":_vm.closeDialogType}})],1)],1),_c('v-card',{staticClass:"elevation-0 py-4 padding2",staticStyle:{"background":"transparent"}},[_c('v-card-title',{staticStyle:{"background":"white"}},[_vm._v("Motivos de Rechazo "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Right tooltip")])]),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0",staticStyle:{"height":"30px","font-size":"13px","font-weight":"400","text-transform":"capitalize","color":"#1976d1","background-color":"rgb(25 118 209 / 10%)!important"},attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogRejection=true}}},[_vm._v("Agregar Nuevo")])],1),_c('rejections',{staticClass:"px-6"}),_c('v-dialog',{attrs:{"max-width":"350px"},model:{value:(_vm.dialogRejection),callback:function ($$v) {_vm.dialogRejection=$$v},expression:"dialogRejection"}},[_c('createRejections',{on:{"closeDialogRejection":_vm.closeDialogRejection}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }